.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: min(72rem, 90%); */
    background: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
}
.content {
    display: flex;
    padding: 1.25rem;
    gap: 1rem;
    align-items: center;
}