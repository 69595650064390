
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.login-wrapper {
  background-image: url('./assets/images/bg.png');
}
*{
  outline: none !important;
  /* line-height: 1; */
  font-family: Inter !important;

}
text {
  fill: #64748B !important;
}

html, body {
  font-size: 14px;
  overflow: hidden;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* .rs-picker-daterange
 .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
right: auto; left: 12px;
}

.rs-picker-daterange * {
  padding-right: 0 !important;
}
*/
.rs-picker-daterange > div {
  padding-left: 50px;
  background-color: red;
  border: 1px #CBD5E1 solid !important;
  height : 33px
}

a:active, a:focus, a:hover{
  text-decoration: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0; 
}

@media (max-width: 1500px) {
  html, body {
    font-size: 12px;
  }
}
@media (max-width: 1300px) {
  html, body {
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  html, body {
    font-size: 9px;
  }
}