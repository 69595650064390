.cardBoxContainer {
    flex: 0 0 calc(25% - 1.3rem) !important;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .cardBoxContainer {
        flex: 0 0 calc(33% - 0.9rem);
    }
}
@media (max-width: 900px) {
    .cardBoxContainer {
        flex: 0 0 calc(50% - 0.9rem);
    }
}