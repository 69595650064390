.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(62rem, 90%);
    max-height: 90%;
    min-height: 70%;
    background: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  /* * header */
  
  .modalWrapper > .header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    align-items: flex-start;
  }
  .modalWrapper > .header h1 {
    margin: 0;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #344054;

    display: flex;
    gap: 8px;
    align-items: center;
  }
  .subHeading {
    color: var(--gray-500, #64748B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
  }
  /* .modalWrapper > .header img:first-child {
    width: 3.2rem;
    height: 3.2rem;
  } */
  /* .modalWrapper > .header img:last-child {
    position: absolute;
    right: 1.3rem;
    top: 1.3rem;
    width: 1.2rem;
  } */
  
  .mainContainer {
    /* background: #f9fafb; */
    padding: 20px;
    border-bottom: 1px solid #eaecf0;
    overflow: auto;

    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .petPoojaChip {
    border-radius: 18px;
    background: var(--error-50, #FEF2F2);
    
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    color: var(--error-700, #B91C1C);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: -0.12px;
  }
  .tableContainer {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    display: contents;
  }
  .loadingBox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selectedFoodTitle {
    color: #000;
    font-family: Inter;
    font-size: 14.999px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.999px; /* 140% */
    letter-spacing: -0.15px;
  }
  .foodImages {
    background: #D9D9D9;
  }
  
  /* Footer */
  .footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .footer > div {
    margin: 0 0.5rem;
  }
  
  @media (max-width: 400px) {
    .inputContainer {
      flex: 0 0 100%;
    }
  }