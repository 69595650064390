.addRestaurantPageWrapper {

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* width: 982px; */

    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.19);
}

.helloWord {
    font-family: Inter;
    font-size: 1.87rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.01em;
    text-align: left;
    margin-left: 40px;
    /* margin-top: 20px; */
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 700;
    /* line-height: 40px; */
    letter-spacing: -0.01em;
    text-align: left;

}

.welcomeWord {
    /* margin-left: 40px; */
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: -0.01em;
    text-align: left;

}

.restaurantsHeaderMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0 1rem 0;
}

.restaurantsHeaderLeft {
    /* float: left;
    width: 50%; */
    padding-top: 20px;
}

.restaurantsHeaderRight {
    float: right;
    margin-right: 2.4rem
        /* margin-left: 6.25rem; */
}

.restaurantMainLayout {
    box-sizing: border-box;
    /* width: 982px; */
    height: 76px;
    background-image: url("https://images.pexels.com/photos/7130555/pexels-photo-7130555.jpeg?cs=srgb&dl=pexels-codioful-%28formerly-gradienta%29-7130555.jpg&fm=jpg");
    ;

}

.formFieldWrapper {
    background-color: #fff;
    height: 42.43rem;
    border-radius: 0.3rem;
    padding: 1rem 0 1rem 0;
}

.formFields {
    /* margin: 0.8rem; */
    margin-top: 1.25rem;
    column-gap: 1rem;
    /* margin-top: 0.7rem; */
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;


}

.formTextFields {
    flex: 1;
    /* margin: 0.7rem; */

}

.formTextFields2 {
    flex: 2;
}

/* 
.formFields2 {

    display: flex;
    justify-content: space-evenly;
} */

.filePublish {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* margin: 50px auto; */
    /* width: 447px;
  height: 111px; */
    /* padding: 30px; */
}

.filePublishIcon {
    background: #F1F5F9;
    border-radius: 10px;
    margin: 17px 0 0 123px;
}

.formFieldLayout {
    padding: 1.2rem;
}

.cancelSaveBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.cancelSaveBtnOwner {
    display: flex;
    justify-content: flex-end;
    margin-top: 15rem;

}

.restaurantformFields {
    margin-top: 0.6rem;
}

.establishmentDetailsBox {
    background-color: rgb(245, 245, 253);
    /* width: 443.5px; */
    height: 195px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    border-radius: 0.7rem;
}

.establishmentDetails {

    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
}

.restaurantTypeRight {
    background-color: red;
    width: 443.5px;
    height: 195px;
}

.establishmentDetailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: 1rem 0 1rem 0; */
}

.establishmentDetailsHeaderMain {
    padding: 0.5rem;
}

.categoriesSelectList {
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0.7rem;
}

.dayTimeList {
    display: flex;
    border: 1px;
    border-radius: 10px;
    background-color: #f8fafc;
    /* padding: 0.5rem; */
}

.dayTimeListMain {
    /* display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
    row-gap: 10px; */

}

.SaveBtn {
    display: flex;
    margin: 3rem 1.2rem;
    justify-content: flex-end;
}

.restaurantType {
    display: grid;
}


.fromToTimes {
    display: flex;
    column-gap: 10px;
}

.checkboxField {
    background-color: red;
}

.timeFieldMain {
    display: flex;
    border: 1px solid #EAECF0;
    /* width: 297px; */
    min-height: 50px;
    padding: 10px;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #CBD5E1;
    /* padding-top: 10px; */
    column-gap: 5px;
    /* padding-left: 10px; */
    margin-left: auto;
    flex-wrap: wrap;
}
.timeFieldMain > label + * {
    display: flex;
    gap: 4px;
    align-items: center;
    /* flex-wrap: wrap; */
    /* justify-content: center; */


}
.timeFieldMain > label + * div {
    /* flex: 1 1 100%; */
    /* width: 100%; */
    text-align: center;
}

.dayTimeFieldMain {
    display: flex;
    column-gap: 3px;


}

.dayTimeFieldBoxWrapper {
    flex: 0 0 calc(33.33% - 14px);
}

.dayTimeFieldBox {
    /* display: grid;
    grid-template-columns: repeat(3, 2.5fr);
    row-gap: 10px;
    column-gap: 7px; */
}

.timeFieldMainS {
    display: flex;
    column-gap: 30px;
}

.fromToTimes {
    padding-top: 20px;
}

.categoryType {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 25px;
}

.container {
    /* min-height: 100%; */
    display: flex;
    flex-direction: column;
    /* margin: 50px auto; */
    /* width: 447px; */
    height: 100px;
    padding: 19px;
    border: 1px dashed #64748B;
    border-radius: 1rem;
    row-gap: 25px;
    /* margin-left: 15px; */

}

.box1 {
    display: flex;
    width: 48px;
    height: 48px;
    background-color: #F1F5F9;
    border-radius: 40px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
}

.addIconMain {
    display: flex;
    justify-content: space-around;
}

@media (max-width: 1580px) {
    .dayTimeFieldBoxWrapper {
        flex: 0 0 calc(50% - 14px);
    }
}
@media (max-width: 1200px) {
    .dayTimeFieldBoxWrapper {
        flex: 0 0 calc(100% - 14px);
    }
}